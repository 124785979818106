// import React, { useEffect, useState } from "react";
// import "./Dropdowns.css";

// const Dropdowns = () => {
//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState("");
//   const [devices, setDevices] = useState([]);
//   const [selectedDevice, setSelectedDevice] = useState("");
//   const [deviceData, setDeviceData] = useState(null); // State to hold device data

//   // Function to get current date in the required format (DD-MM-YYYY)
//   const getCurrentDate = () => {
//     const today = new Date();
//     const day = String(today.getDate()).padStart(2, "0");
//     const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
//     const year = today.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   // Fetch clients
//   useEffect(() => {
//     const fetchClients = async () => {
//       try {
//         const response = await fetch("http://127.0.0.1:8000/api/host_campus");
//         const data = await response.json();
//         setClients(data); // assuming data is an array of client objects
//       } catch (error) {
//         console.error("Error fetching clients:", error);
//       }
//     };

//     fetchClients();
//   }, []);

//   // Fetch devices based on the selected client
//   useEffect(() => {
//     const fetchDevices = async () => {
//       if (selectedClient) {
//         try {
//           const response = await fetch(
//             `http://127.0.0.1:8000/api/campus/${selectedClient}`
//           );
//           const data = await response.json();
//           setDevices(data); // assuming data is an array of device objects
//         } catch (error) {
//           console.error("Error fetching devices:", error);
//         }
//       } else {
//         setDevices([]); // Clear devices if no client is selected
//       }
//     };

//     fetchDevices();
//   }, [selectedClient]);

//   // Fetch device data for the current date when a device is selected
//   useEffect(() => {
//     const fetchDeviceData = async () => {
//       if (selectedDevice) {
//         const currentDate = getCurrentDate(); // Get current date in DD-MM-YYYY format
//         try {
//           const response = await fetch(
//             `http://127.0.0.1:8000/api/export-csv/${selectedClient}/${currentDate}/${selectedDevice}`
//           );
//           const data = await response.json();
//           setDeviceData(data); // Store the fetched device data
//         } catch (error) {
//           console.error("Error fetching device data:", error);
//         }
//       } else {
//         setDeviceData(null); // Clear device data if no device is selected
//       }
//     };

//     fetchDeviceData();
//   }, [selectedDevice]);

//   // Data fields to be displayed in cards
//   const dataFields = [
//     { name: "KW", value: deviceData?.kw },
//     { name: "KWR", value: deviceData?.kwR },
//     { name: "KWY", value: deviceData?.kwy },
//     { name: "KWB", value: deviceData?.kwB },
//     { name: "PF Average", value: deviceData?.pf_avg },
//     { name: "KVA", value: deviceData?.KVA },
//     { name: "VLL", value: deviceData?.VLL },
//     { name: "VLN", value: deviceData?.VLN },
//     { name: "Current", value: deviceData?.Current },
//     { name: "kwh", value: deviceData?.kwh_difference },
//     { name: "VAH", value: deviceData?.vah_difference },
//     { name: "VARH", value: deviceData?.varh_difference },
//     { name: "VARHC", value: deviceData?.varhc_difference },
//   ];

//   return (
//     <div>
//       {/* Dropdowns Wrapper */}
//       <div className="dropdown-wrapper">
//         {/* Client Dropdown */}
//         <div className="dropdown-container">
//           <select
//             value={selectedClient}
//             onChange={(e) => {
//               setSelectedClient(e.target.value);
//               setSelectedDevice(""); // Reset selected device when client changes
//               setDeviceData(null); // Clear device data
//             }}
//             className="single-line-dropdown"
//           >
//             <option value="" disabled>
//               Select Client
//             </option>
//             {clients.map((client) => (
//               <option key={client.client_id} value={client.client_id}>
//                 {client.client_name}
//               </option>
//             ))}
//           </select>
//         </div>

//         {/* Device Dropdown */}
//         <div className="dropdown-container">
//           <select
//             value={selectedDevice}
//             onChange={(e) => setSelectedDevice(e.target.value)}
//             className="single-line-dropdown"
//             disabled={!selectedClient} // Disable if no client is selected
//           >
//             <option value="" disabled>
//               Select Device
//             </option>
//             {devices.map((device) => (
//               <option key={device.device_id} value={device.device_id}>
//                 {device.device_name}
//               </option>
//             ))}
//           </select>
//         </div>
//       </div>

//       {/* Display Device Data */}
//       {deviceData && (
//         <div className="device-data-container">
//           {dataFields.map((field, index) => (
//             <div key={index} className="device-card">
//               <h3>{field.name}</h3>
//               <p>{field.value || "N/A"}</p>{" "}
//               {/* Show value or "N/A" if not available */}
//               <img
//                 src={`${process.env.PUBLIC_URL}/icons/light.png`}
//                 alt="Light Icon"
//                 className="light-icon"
//               />
//             </div>
//           ))}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Dropdowns;
// import React, { useEffect, useState } from "react";
// import "../style/Dropdowns.css";

// const Dropdowns = () => {
//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState("");
//   const [devices, setDevices] = useState([]);
//   const [selectedDevice, setSelectedDevice] = useState("");
//   const [deviceData, setDeviceData] = useState(null); // State to hold device data

//   // Function to get current date in the required format (DD-MM-YYYY)
//   const getCurrentDate = () => {
//     const today = new Date();
//     const day = String(today.getDate()).padStart(2, "0");
//     const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
//     const year = today.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   // Fetch clients
//   useEffect(() => {
//     const fetchClients = async () => {
//       try {
//         const response = await fetch("http://127.0.0.1:8000/api/host_campus");
//         const data = await response.json();
//         setClients(data);
//       } catch (error) {
//         console.error("Error fetching clients:", error);
//       }
//     };

//     fetchClients();
//   }, []);

//   // Fetch devices based on the selected client
//   useEffect(() => {
//     const fetchDevices = async () => {
//       if (selectedClient) {
//         try {
//           const response = await fetch(
//             `http://127.0.0.1:8000/api/campus/${selectedClient}`
//           );
//           const data = await response.json();
//           setDevices(data);
//         } catch (error) {
//           console.error("Error fetching devices:", error);
//         }
//       } else {
//         setDevices([]);
//       }
//     };

//     fetchDevices();
//   }, [selectedClient]);

//   // Fetch device data for the current date when a device is selected
//   useEffect(() => {
//     const fetchDeviceData = async () => {
//       if (selectedDevice) {
//         const currentDate = getCurrentDate();
//         try {
//           const response = await fetch(
//             `http://127.0.0.1:8000/api/export-csv/${selectedClient}/${currentDate}/${selectedDevice}`
//           );
//           const data = await response.json();
//           setDeviceData(data);
//         } catch (error) {
//           console.error("Error fetching device data:", error);
//         }
//       } else {
//         setDeviceData(null);
//       }
//     };

//     fetchDeviceData();
//   }, [selectedDevice]);

//   // Data fields for the main left-side cards
//   const leftSideFields = [
//     { name: "KW", value: deviceData?.kw },
//     { name: "KWR", value: deviceData?.kwR },
//     { name: "KWY", value: deviceData?.kwy },
//     { name: "KWB", value: deviceData?.kwB },
//     { name: "PF Average", value: deviceData?.pf_avg },
//     { name: "KVA", value: deviceData?.KVA },
//     { name: "VLL", value: deviceData?.VLL },
//     { name: "VLN", value: deviceData?.VLN },
//     { name: "Current", value: deviceData?.Current },
//   ];

//   // Data fields for the grouped box with export values
//   const groupedFields = [
//     {
//       name: "IMPORT KWH",
//       value1: deviceData?.kwh_difference,
//       value2: deviceData?.kw_export_difference, // Export value
//     },
//     {
//       name: "IMPORT VAH",
//       value1: deviceData?.vah_difference,
//       value2: deviceData?.vah_export_difference, // Export value
//     },
//     {
//       name: "IMPORT VARH",
//       value1: deviceData?.varh_difference,
//       value2: deviceData?.varhi_export_difference,
//     },
//     {
//       name: "IMPORT VARHC",
//       value1: deviceData?.varhc_difference,
//       value2: deviceData?.varhc_export_difference,
//     },
//   ];

//   // Reset function to clear selections
//   const handleReset = () => {
//     setSelectedClient("");
//     setSelectedDevice("");
//     setDeviceData(null);
//   };

//   return (
//     <div>
//       <div className="dropdown-wrapper">
//         <div className="dropdown-container">
//           <select
//             value={selectedClient}
//             onChange={(e) => {
//               setSelectedClient(e.target.value);
//               setSelectedDevice("");
//               setDeviceData(null);
//             }}
//             className="single-line-dropdown"
//           >
//             <option value="" disabled>
//               Select Client
//             </option>
//             {clients.map((client) => (
//               <option key={client.client_id} value={client.client_id}>
//                 {client.client_name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="dropdown-container">
//           <select
//             value={selectedDevice}
//             onChange={(e) => setSelectedDevice(e.target.value)}
//             className="single-line-dropdown"
//             disabled={!selectedClient}
//           >
//             <option value="" disabled>
//               Select Device
//             </option>
//             {devices.map((device) => (
//               <option key={device.device_id} value={device.device_id}>
//                 {device.device_name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <button className="restart-button" onClick={handleReset}>
//           <span className="restart-symbol">⟳</span>
//         </button>
//       </div>

//       {/* Flex container for left side cards and grouped box */}
//       <div className="cards-wrapper">
//         {/* Left side cards */}
//         <div className="left-side-container">
//           {leftSideFields.map((field, index) => (
//             <div key={index} className="device-card">
//               <div className="card-content">
//                 <span className="card-label">{field.name}</span>
//                 <span className="card-value">{field.value || "0"}</span>
//                 <h1>---</h1>
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* Grouped box for specific fields */}
//         <div className="grouped-box">
//           {/* <div className="group-title">Grouped Data</div> */}
//           <div className="grouped-grid">
//             {groupedFields.map((field, index) => (
//               <div key={index} className="grouped-card">
//                 <span>
//                   {field.name === "IMPORT KWH" && (
//                     <span className="arrow down">↓</span>
//                   )}{" "}
//                   {/* Red down arrow for KWH */}
//                   {field.name === "IMPORT VARH" && (
//                     <span className="arrow down">↓</span>
//                   )}{" "}
//                   {field.name === "IMPORT VAH" && (
//                     <span className="arrow down">↓</span>
//                   )}{" "}
//                   {field.name === "IMPORT VARHC" && (
//                     <span className="arrow down">↓</span>
//                   )}{" "}
//                   {/* Green up arrow for VARH */}
//                   {field.name}: {field.value1 || "0"}
//                   {field.value2 !== undefined && (
//                     <>
//                       <br />
//                       <span className="arrow up">↑</span>
//                       Export KWH: {field.value2 || "0"}
//                     </>
//                   )}
//                 </span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Dropdowns;
// import React, { useEffect, useState } from "react";
// import "../style/Dropdowns.css";

// const Dropdowns = () => {
//   const [clients, setClients] = useState([]);
//   const [selectedClient, setSelectedClient] = useState("");
//   const [devices, setDevices] = useState([]);
//   const [selectedDevice, setSelectedDevice] = useState("");
//   const [deviceData, setDeviceData] = useState(null);

//   // Function to get current date in the required format (DD-MM-YYYY)
//   const getCurrentDate = () => {
//     const today = new Date();
//     const day = String(today.getDate()).padStart(2, "0");
//     const month = String(today.getMonth() + 1).padStart(2, "0");
//     const year = today.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   // Fetch clients
//   useEffect(() => {
//     const fetchClients = async () => {
//       try {
//         const response = await fetch("http://127.0.0.1:8000/api/host_campus");
//         const data = await response.json();
//         setClients(data);
//       } catch (error) {
//         console.error("Error fetching clients:", error);
//       }
//     };

//     fetchClients();
//   }, []);

//   // Fetch devices based on the selected client
//   useEffect(() => {
//     const fetchDevices = async () => {
//       if (selectedClient) {
//         try {
//           const response = await fetch(
//             `http://127.0.0.1:8000/api/campus/${selectedClient}`
//           );
//           const data = await response.json();
//           setDevices(data);
//         } catch (error) {
//           console.error("Error fetching devices:", error);
//         }
//       } else {
//         setDevices([]);
//       }
//     };

//     fetchDevices();
//   }, [selectedClient]);

//   // Fetch device data for the current date when a device is selected
//   useEffect(() => {
//     const fetchDeviceData = async () => {
//       if (selectedDevice) {
//         const currentDate = getCurrentDate();
//         try {
//           const response = await fetch(
//             `http://127.0.0.1:8000/api/export-csv/${selectedClient}/${currentDate}/${selectedDevice}`
//           );
//           const data = await response.json();
//           setDeviceData(data);
//         } catch (error) {
//           console.error("Error fetching device data:", error);
//         }
//       } else {
//         setDeviceData(null);
//       }
//     };

//     fetchDeviceData();
//   }, [selectedDevice]);

//   // Data fields for the main left-side cards
//   const leftSideFields = [
//     { name: "KW", value: deviceData?.kw },
//     { name: "KWR", value: deviceData?.kwR },
//     { name: "KWY", value: deviceData?.kwy },
//     { name: "KWB", value: deviceData?.kwB },
//     { name: "PF Average", value: deviceData?.pf_avg },
//     { name: "KVA", value: deviceData?.KVA },
//     { name: "VLL", value: deviceData?.VLL },
//     { name: "VLN", value: deviceData?.VLN },
//     { name: "Current", value: deviceData?.Current },
//   ];

//   // Data fields for the grouped box with export values
//   const groupedFields = [
//     {
//       name: "IMPORT KWH",
//       value1: deviceData?.kwh_difference,
//       value2: deviceData?.kw_export_difference,
//     },
//     {
//       name: "IMPORT VAH",
//       value1: deviceData?.vah_difference,
//       value2: deviceData?.vah_export_difference,
//     },
//     {
//       name: "IMPORT VARH",
//       value1: deviceData?.varh_difference,
//       value2: deviceData?.varhi_export_difference,
//     },
//     {
//       name: "IMPORT VARHC",
//       value1: deviceData?.varhc_difference,
//       value2: deviceData?.varhc_export_difference,
//     },
//   ];

//   // Reset function to clear selections
//   const handleReset = () => {
//     setSelectedClient("");
//     setSelectedDevice("");
//     setDeviceData(null);
//   };

//   return (
//     <div>
//       <div className="dropdown-wrapper">
//         <div className="dropdown-container">
//           <select
//             value={selectedClient}
//             onChange={(e) => {
//               setSelectedClient(e.target.value);
//               setSelectedDevice("");
//               setDeviceData(null);
//             }}
//             className="single-line-dropdown"
//           >
//             <option value="" disabled>
//               Select Client
//             </option>
//             {clients.map((client) => (
//               <option key={client.client_id} value={client.client_id}>
//                 {client.client_name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <div className="dropdown-container">
//           <select
//             value={selectedDevice}
//             onChange={(e) => setSelectedDevice(e.target.value)}
//             className="single-line-dropdown"
//             disabled={!selectedClient}
//           >
//             <option value="" disabled>
//               Select Device
//             </option>
//             {devices.map((device) => (
//               <option key={device.device_id} value={device.device_id}>
//                 {device.device_name}
//               </option>
//             ))}
//           </select>
//         </div>

//         <button className="restart-button" onClick={handleReset}>
//           <span className="restart-symbol">⟳</span>
//         </button>
//       </div>

//       {/* Display col_1 date and time above the cards */}
//       {deviceData?.col_1 && (
//         <div className="date-time-display">
//           Date and Time: {deviceData.col_1}
//         </div>
//       )}

//       {/* Flex container for left side cards and grouped box */}
//       <div className="cards-wrapper">
//         <div className="left-side-container">
//           {leftSideFields.map((field, index) => (
//             <div key={index} className="device-card">
//               <div className="card-content">
//                 <span className="card-label">{field.name}</span>
//                 <span className="card-value">{field.value || "0"}</span>
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="grouped-box">
//           <div className="grouped-grid">
//             {groupedFields.map((field, index) => (
//               <div key={index} className="grouped-card">
//                 <span className="field-name">{field.name}:</span>
//                 <span className="import-export">
//                   <span className="import-text">
//                     <span className="down-arrow">⬇</span> {field.value1 || "0"}
//                   </span>
//                   <br />
//                   <span className="export-text">
//                     <span className="up-arrow">⬆</span> {field.value2 || "0"}
//                   </span>
//                 </span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
// export default Dropdowns;
import React, { useEffect, useState } from "react";
import "../style/Dropdowns.css";

const Dropdowns = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [deviceData, setDeviceData] = useState(null);

  // Function to get current date in the required format (DD-MM-YYYY)
  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Fetch clients
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch("http://127.0.0.1:8000/api/host_campus");
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, []);

  // Fetch devices based on the selected client
  useEffect(() => {
    const fetchDevices = async () => {
      if (selectedClient) {
        try {
          const response = await fetch(
            `http://127.0.0.1:8000/api/campus/${selectedClient}`
          );
          const data = await response.json();
          setDevices(data);
        } catch (error) {
          console.error("Error fetching devices:", error);
        }
      } else {
        setDevices([]);
      }
    };

    fetchDevices();
  }, [selectedClient]);

  // Fetch device data for the current date when a device is selected
  useEffect(() => {
    const fetchDeviceData = async () => {
      if (selectedDevice) {
        const currentDate = getCurrentDate();
        try {
          const response = await fetch(
            `http://127.0.0.1:8000/api/export-csv/${selectedClient}/${currentDate}/${selectedDevice}`
          );
          const data = await response.json();
          setDeviceData(data);
        } catch (error) {
          console.error("Error fetching device data:", error);
        }
      } else {
        setDeviceData(null);
      }
    };

    fetchDeviceData();
  }, [selectedDevice]);

  // Data fields for the main left-side cards
  const leftSideFields = [
    { name: "KW", value: deviceData?.kw },
    { name: "KWR", value: deviceData?.kwR },
    { name: "KWY", value: deviceData?.kwy },
    { name: "KWB", value: deviceData?.kwB },
    { name: "PF Average", value: deviceData?.pf_avg },
    { name: "KVA", value: deviceData?.KVA },
    { name: "VLL", value: deviceData?.VLL },
    { name: "VLN", value: deviceData?.VLN },
    { name: "Current", value: deviceData?.Current },
  ];

  // Data fields for the grouped box with export values
  const groupedFields = [
    {
      name: "IMPORT",
      value1: deviceData?.kwh_difference,
      value2: deviceData?.kw_export_difference,
    },
    {
      name: "IMPORT",
      value1: deviceData?.vah_difference,
      value2: deviceData?.vah_export_difference,
    },
    {
      name: "IMPORT",
      value1: deviceData?.varh_difference,
      value2: deviceData?.varhi_export_difference,
    },
    {
      name: "IMPORT",
      value1: deviceData?.varhc_difference,
      value2: deviceData?.varhc_export_difference,
    },
  ];

  // Reset function to clear selections
  const handleReset = () => {
    setSelectedClient("");
    setSelectedDevice("");
    setDeviceData(null);
  };

  return (
    <div>
      <div className="dropdown-wrapper">
        <div className="dropdown-container">
          <select
            value={selectedClient}
            onChange={(e) => {
              setSelectedClient(e.target.value);
              setSelectedDevice("");
              setDeviceData(null);
            }}
            className="single-line-dropdown"
          >
            <option value="" disabled>
              Select Client
            </option>
            {clients.map((client) => (
              <option key={client.client_id} value={client.client_id}>
                {client.client_name}
              </option>
            ))}
          </select>
        </div>

        <div className="dropdown-container">
          <select
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e.target.value)}
            className="single-line-dropdown"
            disabled={!selectedClient}
          >
            <option value="" disabled>
              Select Device
            </option>
            {devices.map((device) => (
              <option key={device.device_id} value={device.device_id}>
                {device.device_name}
              </option>
            ))}
          </select>
        </div>

        <button className="restart-button" onClick={handleReset}>
          <span className="restart-symbol">⟳</span>
        </button>
      </div>

      {/* Display IMPORT label before the cards */}
      <div className="import-label"></div>

      {/* Display col_1 date and time above the cards */}
      {deviceData?.col_1 && (
        <div className="date-time-display">
          Date and Time: {deviceData.col_1}
        </div>
      )}

      {/* Flex container for left side cards and grouped box */}
      <div className="cards-wrapper">
        {/* Left side cards for device data */}
        <div className="left-side-container">
          {leftSideFields.map((field, index) => (
            <div key={index} className="device-card">
              <div className="card-content">
                <img
                  src={`${process.env.PUBLIC_URL}/icons/flash.png`}
                  alt="Flash Icon"
                  className="flash-icon"
                />
                <span className="card-label">{field.name}</span>
                <span className="card-value">{field.value || "0"}</span>
              </div>
            </div>
          ))}
        </div>

        {/* Grouped cards for import/export data */}
        <div className="grouped-box">
          <div className="grouped-grid">
            {groupedFields.map((field, index) => (
              <div key={index} className="grouped-card">
                <span className="import-text">
                  {/* Conditionally render the "KWH" label with green arrow for the first card */}
                  {index === 0 && (
                    <span className="field-name">
                      <span style={{ fontWeight: "bold" }}>KWH</span>{" "}
                      <span className="down-arrow">⬇</span>
                    </span>
                  )}

                  {index === 1 && (
                    <span className="field-name">
                      <span style={{ fontWeight: "bold" }}>VAH</span>{" "}
                      <span className="down-arrow">⬇</span>
                    </span>
                  )}
                  {index === 2 && (
                    <span className="field-name">
                      <span style={{ fontWeight: "bold" }}>VARHI</span>{" "}
                      <span className="down-arrow">⬇</span>
                    </span>
                  )}
                  {index === 3 && (
                    <span className="field-name">
                      <span style={{ fontWeight: "bold" }}>VARHC</span>{" "}
                      <span className="down-arrow">⬇</span>
                    </span>
                  )}
                  <span className="field-name">{field.name}:</span>
                  <span className="import-export">{field.value1 || "0"}</span>
                  <br />
                  <span className="export-text">
                    <span className="up-arrow">⬆</span>
                    Export {field.value2 || "0"}
                  </span>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdowns;
