import logo from "./logo.svg";
import "./App.css";
import React from "react";
import Main from "./components/main";
import Sidebar from "./components/sidebar";
import Home_page from "./components/home";
import Demo from "./components/demo";
import Login_page from "./components/login";
import ClientSelector from "./components/ClientSelector";
import ClientDropdown from "./components/ClientDropdown";
import CurrentValue from "./components/CurrentValue";
import { Login } from "@mui/icons-material";
function App() {
  return (
    <div className="App">
      <Main />
      {/* <CurrentValue /> */}
      {/* <Login_page/> */}
      {/* <ClientDropdown /> */}
      {/* <ClientSelector /> */}
      {/* <Login_page /> */}
    </div>
  );
}

export default App;
