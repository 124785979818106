import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/DeviceDetailsForm.css';  

const DeviceDetailsForm = () => {
  const [clientNames, setClientNames] = useState([]);
  const [clientIds, setClientIds] = useState([]);
  const [deviceIds, setDeviceIds] = useState([]);
  const [category, setCategory] = useState('');
  const [selectedClientName, setSelectedClientName] = useState('');
  const [selectedClientId, setSelectedClientId] = useState('');
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isCategoryEditable, setIsCategoryEditable] = useState(false); // Track if category is editable

  // Fetch suggestions on component mount
  useEffect(() => {
    axios
      .get('https://testbiller1.hetadatain.com/api/device-suggestions')
      .then((response) => {
        setClientNames(response.data.client_names);
        setClientIds(response.data.client_ids);
        setDeviceIds(response.data.device_ids);
      })
      .catch((error) => {
        console.error('Error fetching device suggestions:', error);
      });
  }, []);

  // Fetch the existing category when the user selects a device
  useEffect(() => {
    if (selectedClientName && selectedClientId && selectedDeviceId) {
      setLoading(true);

      axios
        .get('https://testbiller1.hetadatain.com/api/get-category', {
          params: {
            client_name: selectedClientName,
            client_id: selectedClientId,
            device_id: selectedDeviceId,
          },
        })
        .then((response) => {
          setCategory(response.data.category);
          setIsCategoryEditable(false); // Initially, category is not editable
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching existing category:', error);
          setLoading(false);
        });
    }
  }, [selectedClientName, selectedClientId, selectedDeviceId]);

  // Handle category update
  const handleUpdateCategory = () => {
    if (!selectedClientName || !selectedClientId || !selectedDeviceId || !category) {
      alert('Please fill in all fields');
      return;
    }

    const data = {
      client_name: selectedClientName,
      client_id: selectedClientId,
      device_id: selectedDeviceId,
      category,
    };

    axios
      .post('https://testbiller1.hetadatain.com/api/update-category', data)
      .then((response) => {
        alert(response.data.message);
      })
      .catch((error) => {
        console.error('Error updating category:', error);
        alert('Error updating category');
      });
  };

  const handleCategoryChange = () => {
    setIsCategoryEditable(true); // Allow editing the category
  };

  return (
    <div className="form-container">
      <h2 className="form-heading">Update Device Category</h2> {/* Heading above the box */}

      <div className="form-box">
        <div className="form-group">
          <label>Client Name</label>
          <select
            value={selectedClientName}
            onChange={(e) => setSelectedClientName(e.target.value)}
          >
            <option value="">Select Client Name</option>
            {clientNames.map((clientName, index) => (
              <option key={index} value={clientName}>
                {clientName}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Client ID</label>
          <select
            value={selectedClientId}
            onChange={(e) => setSelectedClientId(e.target.value)}
          >
            <option value="">Select Client ID</option>
            {clientIds.map((clientId, index) => (
              <option key={index} value={clientId}>
                {clientId}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Device ID</label>
          <select
            value={selectedDeviceId}
            onChange={(e) => setSelectedDeviceId(e.target.value)}
          >
            <option value="">Select Device ID</option>
            {deviceIds.map((deviceId, index) => (
              <option key={index} value={deviceId}>
                {deviceId}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label>Category</label>
          <p>{category}</p> {/* Display existing category */}
          {category && !isCategoryEditable && (
            <div>
              <p>Do you want to change the category?</p>
              <button onClick={handleCategoryChange}>Yes, Edit Category</button>
            </div>
          )}
          {isCategoryEditable && (
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
          )}
        </div>

        {loading && <p>Loading...</p>}

        <button onClick={handleUpdateCategory}>Update Category</button>
      </div>
    </div>
  );
};

export default DeviceDetailsForm;
